import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Hidden,
  Theme,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import React from 'react';
import ReactPlayer from 'react-player';

import {
  FacebookIcon,
  GraphImg,
  InstagramIcon,
  iPhone131x,
  iPhone132x,
  MacbookPro1x,
  MacbookPro2x,
  SnapchatIcon,
  TikTokIcon,
  TumblrIcon,
  TwitterIcon,
  YoutubeIcon,
} from '../assets';
import Card from '../components/card';
import Disappearing from '../components/disappearing';
import Layout from '../components/layout';
import PageHeader from '../components/pageHeader';
import QuoteCarousel from '../components/quoteCarousel';
import SEO from '../components/seo';
import { SignUpContext } from '../components/signUpDialog/signUpContext';
import { SlantAngle, SlantContainer, SlantGradient } from '../components/slant';
import lottieInteractive from '../lottie/interactive.json';
import lottiePersonalized from '../lottie/personalized.json';
import lottieRocket from '../lottie/rocket.json';
import theme from '../root/theme';

const socialIconBreakpoints = {
  lineBreakOne: 1353,
  headerContentShrink: 1280,
  lineBreakTwo: 1083,
  bottomIconsOnly: 960,
};

const cardBreakpoints = {
  twoRows: 820,
  column: 750,
};

const miscBreakpoints = {
  hidePhoneImg: 780,
};

const useStyles = makeStyles((theme: Theme) => {
  return {
    button: {
      backgroundColor: '#FDC72A',
      borderRadius: 8,
      boxShadow: '0 4px 4px 0 rgba(0,0,0,0.50)',
      marginTop: 30,
      textTransform: 'none',
    },
    cardBig: {
      boxShadow: '3px 3px 11px 7px rgba(98,97,97,0.50)',
      borderRadius: 15,
      backgroundColor: '#fff',
      maxWidth: 824,
      margin: 'auto',
    },
    divider: {
      marginBottom: 35,
      marginTop: 35,
    },
    dividerLeft: {
      marginLeft: '0 !important', // important to override MUI theme styles
      [theme.breakpoints.down(miscBreakpoints.hidePhoneImg)]: {
        marginLeft: 'auto !important',
        marginBottom: '35px',
      },
    },
    attentionContainer: {
      [theme.breakpoints.down(miscBreakpoints.hidePhoneImg)]: {
        display: 'block',
      },
    },
    phoneContainer: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
      alignItems: 'center',
      [theme.breakpoints.down(miscBreakpoints.hidePhoneImg)]: {
        display: 'none',
      },
    },
    phoneImg: {
      height: '500px',
    },
    attentionTypography: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: '700px',
      [theme.breakpoints.down(miscBreakpoints.hidePhoneImg)]: {
        maxWidth: '100%',
        textAlign: 'center',
      },
    },
    laptopContainer: {
      display: 'flex',
      justifyContent: 'center',
      justifyItems: 'center',
      alignItems: 'center',
      [theme.breakpoints.down('sm')]: {
        maxHeight: '300px',
      },
    },
    laptopImg: {
      maxWidth: '100%',
      maxHeight: '100%',
      paddingLeft: '16px',
      paddingRight: '16px',
    },
    sectionCentered: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
    },
    sectionHeader: {
      width: '800px',
      [theme.breakpoints.down('sm')]: {
        width: '85vw',
      },
    },
    sectionTall: {
      paddingTop: 125,
      paddingBottom: 125,
      [theme.breakpoints.down('sm')]: {
        paddingTop: 80,
        paddingBottom: 80,
      },
    },
    sectionShort: {
      paddingTop: 80,
      paddingBottom: 80,
    },
    overflowHidden: {
      overflow: 'hidden',
    },
    relative: {
      position: 'relative',
    },
    graphBackground: {
      position: 'absolute',
      bottom: 0,
      left: '-62%',
      zIndex: -1,
      [theme.breakpoints.up('xs')]: {
        transform: 'translate(-1500px, 100px)',
      },
      [theme.breakpoints.up('sm')]: {
        transform: 'translate(-1150px, 100px)',
      },
      [theme.breakpoints.up('md')]: {
        transform: 'translate(-580px, 100px)',
      },
      [theme.breakpoints.up('lg')]: {
        transform: 'translate(0px, 100px)',
      },
    },
    socialImg: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
    },
    socialTikTok: {
      bottom: 40,
    },
    socialFacebook: {
      bottom: 40,
      [theme.breakpoints.down(socialIconBreakpoints.lineBreakOne)]: {
        bottom: 75,
      },
      [theme.breakpoints.down(socialIconBreakpoints.lineBreakTwo)]: {
        bottom: 110,
      },
      [theme.breakpoints.down(socialIconBreakpoints.bottomIconsOnly)]: {
        bottom: 40,
        right: 40,
      },
    },
    socialSnapchat: {
      bottom: -10,
      [theme.breakpoints.down(socialIconBreakpoints.lineBreakOne)]: {
        bottom: 10,
      },
      [theme.breakpoints.down(socialIconBreakpoints.headerContentShrink)]: {
        bottom: -25,
      },
      [theme.breakpoints.down(socialIconBreakpoints.lineBreakTwo)]: {
        bottom: 5,
      },
      [theme.breakpoints.down(socialIconBreakpoints.bottomIconsOnly)]: {
        bottom: -10,
        right: 20,
      },
    },
    socialInstagram: {
      bottom: 0,
      [theme.breakpoints.down(socialIconBreakpoints.lineBreakOne)]: {
        bottom: 15,
      },
      [theme.breakpoints.down(socialIconBreakpoints.headerContentShrink)]: {
        bottom: -15,
      },
      [theme.breakpoints.down(socialIconBreakpoints.lineBreakTwo)]: {
        bottom: 0,
      },
      [theme.breakpoints.down(socialIconBreakpoints.bottomIconsOnly)]: {
        bottom: -10,
        left: 20,
      },
    },
    socialYoutube: {
      bottom: 60,
      [theme.breakpoints.down(socialIconBreakpoints.lineBreakOne)]: {
        bottom: 95,
      },
      [theme.breakpoints.down(socialIconBreakpoints.lineBreakTwo)]: {
        bottom: 130,
      },
      [theme.breakpoints.down(socialIconBreakpoints.bottomIconsOnly)]: {
        bottom: 50,
        left: 40,
      },
    },
    socialTwitter: {
      bottom: 50,
    },
    center: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    subtitleContainer: {
      textAlign: 'center',
    },
    quoteCarousel: {
      paddingTop: '80px',
      paddingBottom: '80px',
      [theme.breakpoints.between('sm', 'md')]: {
        paddingTop: '40px',
        paddingBottom: '40px',
      },
      [theme.breakpoints.down('sm')]: {
        padding: '30px',
      },
    },
    cardContainer: {
      maxWidth: '33%',
      [theme.breakpoints.down(cardBreakpoints.twoRows)]: {
        maxWidth: '50%',
      },
      [theme.breakpoints.down(cardBreakpoints.column)]: {
        maxWidth: '100%',
      },
    },
    flexCenterContents: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    headerLarge: {
      fontFamily: 'Bebas Neue, Arial, sans-serif',
      fontSize: '64px',
      letterSpacing: 1.52,
      lineHeight: '64px',
      fontWeight: 700,
    },
    headerSmall: {
      fontFamily: 'Bebas Neue, Arial, sans-serif',
      fontSize: '48px',
      letterSpacing: 1.33,
      lineHeight: '48px',
      fontWeight: 700,
    },
  };
});

const IndexPage: React.FC = () => {
  const classes = useStyles({});
  const isScreenSmall = useMediaQuery(theme.breakpoints.down('md'));
  const isScreenLessThan858 = useMediaQuery(theme.breakpoints.down(858));
  const isScreenLessThan705 = useMediaQuery(theme.breakpoints.down(705));
  const isScreenLessThan634 = useMediaQuery(theme.breakpoints.down(634));
  const isScreenXSmall = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Layout>
      <SEO title="Home" />
      <PageHeader
        headlinePrimary="ATTENTION IS A"
        headlineSecondary="TRAINABLE SKILL"
        subtitle="Our 10-Day Course and Focus Coach help teens be fully present so teachers get the attention they deserve."
      />
      <Grid
        container
        className={classes.sectionTall}
        spacing={isScreenSmall ? 3 : 5}
      >
        <div
          id="trigger"
          style={{
            position: 'relative',
            top: isScreenXSmall
              ? 170
              : isScreenLessThan634
              ? 150
              : isScreenLessThan705
              ? 110
              : isScreenLessThan858
              ? 30
              : isScreenSmall
              ? -10
              : -40,
          }}
        />
        <Grid item xs={12}>
          <Grid container spacing={1} className={classes.flexCenterContents}>
            <Grid item xs={12} md={12}>
              <Typography
                variant="h2"
                gutterBottom
                color="textPrimary"
                align="center"
                style={{ paddingLeft: 16, paddingRight: 16 }}
              >
                Removing barriers to learning
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={2}>
          <Hidden only={['xs', 'sm']}>
            <Disappearing
              triggerElement="#trigger"
              toXPx={-200}
              rotation={-270}
            >
              <div className={clsx(classes.socialImg, classes.socialTikTok)}>
                <TikTokIcon />
              </div>
            </Disappearing>
          </Hidden>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={1}>
            <Grid item xs={1} />
            <Grid item xs={10} md={12}>
              <Typography
                variant="subtitle1"
                gutterBottom
                color="textPrimary"
                align="center"
              >
                Teens are now the most stressed age group in the U.S., and they
                live in the most distracting time in history. This makes it
                harder than ever for teachers to do their jobs. We help students
                focus their minds and manage their emotions so they can succeed
                in school.
              </Typography>
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </Grid>
        <Grid item md={2}>
          <Hidden only={['xs', 'sm']}>
            <Disappearing triggerElement="#trigger" toXPx={200}>
              <div className={clsx(classes.socialImg, classes.socialTwitter)}>
                <TwitterIcon />
              </div>
            </Disappearing>
          </Hidden>
        </Grid>
        <Grid container>
          <Hidden only="xs">
            <Grid item sm={3} md={2}>
              <Disappearing
                triggerElement="#trigger"
                toXPx={-200}
                toYPx={200}
                rotation={-270}
              >
                <div
                  className={clsx(classes.socialImg, classes.socialFacebook)}
                >
                  <FacebookIcon />
                </div>
              </Disappearing>
            </Grid>
          </Hidden>
          <Grid item xs={6} sm={3} md={2}>
            <Disappearing
              triggerElement="#trigger"
              toXPx={-200}
              toYPx={200}
              rotation={-270}
            >
              <div className={clsx(classes.socialImg, classes.socialSnapchat)}>
                <SnapchatIcon />
              </div>
            </Disappearing>
          </Grid>
          <Hidden only={['xs', 'sm']}>
            <Grid item md={4}></Grid>
          </Hidden>
          <Grid item xs={6} sm={3} md={2}>
            <Disappearing triggerElement="#trigger" toXPx={200} toYPx={200}>
              <div className={clsx(classes.socialImg, classes.socialInstagram)}>
                <InstagramIcon />
              </div>
            </Disappearing>
          </Grid>
          <Hidden only="xs">
            <Grid item sm={3} md={2}>
              <Disappearing triggerElement="#trigger" toXPx={200} toYPx={200}>
                <div className={clsx(classes.socialImg, classes.socialYoutube)}>
                  <YoutubeIcon />
                </div>
              </Disappearing>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
      <SlantContainer
        angle={SlantAngle.LEFT_TO_RIGHT}
        gradient={SlantGradient.HORIZONTAL}
        onlyTop={true}
      >
        <Grid
          container
          spacing={4}
          className={clsx(classes.sectionShort, classes.attentionContainer)}
        >
          <Grid item sm={5} className={classes.phoneContainer}>
            <img
              className={classes.phoneImg}
              srcSet={`${iPhone131x} 1x, ${iPhone132x} 2x`}
              sizes="(max-width: 600px) 314px, 628px"
              src={iPhone131x}
              alt="iPhone app used to navigate the course"
            />
          </Grid>
          <Grid item xs={12} sm={7} className={classes.attentionTypography}>
            <Box>
              <Typography
                component="h3"
                gutterBottom
                color="textSecondary"
                className={classes.headerLarge}
              >
                The 10-Day Course
              </Typography>
              <Divider
                className={clsx(classes.divider, classes.dividerLeft)}
                variant="middle"
              />
              <Typography
                variant="subtitle1"
                color="textSecondary"
                gutterBottom
              >
                In just 80 minutes, our course helps students learn how to train
                attention and why it&apos;s so important. In turn, they gain
                greater influence over their thoughts and emotions.
              </Typography>
              <Box>
                <SignUpContext.Consumer>
                  {({ showSignUp }): React.ReactNode => (
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={showSignUp}
                    >
                      Get access
                    </Button>
                  )}
                </SignUpContext.Consumer>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </SlantContainer>
      <Grid
        container
        className={clsx(
          classes.relative,
          classes.sectionTall,
          classes.overflowHidden
        )}
      >
        <GraphImg className={classes.graphBackground} />
        <Grid item xs={1} sm={2}></Grid>
        <Grid item xs={10} sm={8}>
          <Box my={10} textAlign="center" className={classes.cardBig} p={5}>
            <Typography variant="h2" gutterBottom color="textPrimary">
              The Focus Coach
            </Typography>
            <Divider className={clsx(classes.divider)} variant="middle" />
            <Typography variant="subtitle1" gutterBottom color="textPrimary">
              The Focus Coach helps students train their attention <u>during</u>
              &nbsp;independent work. By helping students track their focus,
              they naturally become more engaged.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={1} sm={2}></Grid>
      </Grid>
      <Container className={classes.sectionTall}>
        <Grid container spacing={3} justify="center">
          <Grid item xs={12}>
            <Box textAlign="center" mx={2}>
              <Typography variant="h2" gutterBottom color="textPrimary">
                Scientifically-designed with students in mind
              </Typography>
            </Box>
          </Grid>
          <Grid item className={classes.cardContainer}>
            <Card
              title="INTERACTIVE"
              subtitle="Interactive lessons that keep students engaged"
              lottieAnimationData={lottieInteractive}
            />
          </Grid>
          <Grid item className={classes.cardContainer}>
            <Card
              title="PERSONALIZED"
              subtitle="Tailored to each student's needs and interests"
              lottieAnimationData={lottiePersonalized}
            />
          </Grid>
          <Grid item className={classes.cardContainer}>
            <Card
              title="EFFECTIVE"
              subtitle="Taught using best practices from Learning Science"
              lottieAnimationData={lottieRocket}
            />
          </Grid>
        </Grid>
      </Container>
      <SlantContainer
        angle={SlantAngle.RIGHT_TO_LEFT}
        gradient={SlantGradient.VERTICAL}
      >
        <Grid
          container
          spacing={3}
          className={clsx(classes.sectionCentered, classes.sectionShort)}
        >
          <Grid item className={classes.sectionHeader}>
            <Box textAlign="center" style={{ marginBottom: 20 }}>
              <Typography
                component="h3"
                gutterBottom
                color="textSecondary"
                className={classes.headerLarge}
              >
                EASY FOR TEACHERS TO USE
              </Typography>
              <Typography
                variant="subtitle1"
                gutterBottom
                color="textSecondary"
              >
                An intuitive interface allows teachers to effortlessly enroll
                students and track their progress.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} className={classes.laptopContainer}>
            <img
              className={classes.laptopImg}
              srcSet={`${MacbookPro1x} 1x, ${MacbookPro2x} 2x`}
              sizes="(max-width: 600px) 595px, 1190px"
              src={MacbookPro1x}
              alt="laptop showing the course"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Box textAlign="center" className={classes.quoteCarousel}>
              <QuoteCarousel type="teacher" />
            </Box>
          </Grid>
        </Grid>
      </SlantContainer>
      <Container>
        <Grid container spacing={3} className={classes.sectionShort}>
          <Grid item xs={12}>
            <Box my={10} mx={3} textAlign="center">
              <Typography variant="h2" gutterBottom color="textPrimary">
                What teachers are saying
              </Typography>
              <Typography variant="subtitle1" gutterBottom color="textPrimary">
                Learn about Finding Focus from teachers who are using it in
                their classroom
              </Typography>
            </Box>
            <Box
              my={10}
              display="flex"
              justifyContent="center"
              style={{ position: 'relative' }}
            >
              <div
                id="video"
                style={{ position: 'absolute', top: '-30vh' }}
              ></div>
              <ReactPlayer
                url={'https://mrazek.wistia.com/medias/rlthnvvvw8'}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Grid
        container
        spacing={3}
        className={classes.sectionTall}
        style={{
          backgroundImage: 'linear-gradient(-143deg, #2C2E3B 0%, #4D4E69 100%)',
        }}
      >
        <Grid container spacing={3} style={{ justifyContent: 'center' }}>
          <Grid item md={2} sm={1} />
          <Grid item xs={10} md={8} className={classes.subtitleContainer}>
            <Typography
              component="h3"
              gutterBottom
              color="textSecondary"
              className={classes.headerLarge}
            >
              Share Finding Focus for Free
            </Typography>
            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
              Thanks to a grant from the U.S. Dept. of Education, educators can
              share the 10-Day Course and Focus Coach with as many students as
              they like.
            </Typography>
            <SignUpContext.Consumer>
              {({ showSignUp }): React.ReactNode => (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={showSignUp}
                >
                  Start now
                </Button>
              )}
            </SignUpContext.Consumer>
          </Grid>
          <Grid item md={2} sm={1} />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default IndexPage;
